.section-bienvenida {
  position: relative;
  text-align: center;
  height: 90vh;
}
.banner {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.banner h1 {
  font-size: 50px;
}
.boton-descubre {
  position: absolute;
  bottom: 2%;
  width: 100%;
  color: white;
  font-family: "Montserrat", sans-serif;
}

/*SECTION EXPERIENCIA*/

.container-experiencia {
  height: 90vh;
  background-image: url("../assets/images/background-building.jpg");
  background-size: cover;
  backdrop-filter: blur(2px);
  backdrop-filter: brightness(60%);
  backdrop-filter: contrast(40%);
  backdrop-filter: drop-shadow(4px 4px 10px blue);
  backdrop-filter: grayscale(30%);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.text-experiencia {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 50px;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-experiencia strong {
  font-size: 65px;
}
/*SECTION SERVICIOS*/

.container-servicios {
  min-height: 1000px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.container-servicios-title {
  margin: 50px 0px 15px 0px;
}

.container-servicios-title h2 {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}
.container-servicios-title p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0px 20px;
  color: #d8d8d8;
}
.container-boxes {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.box-servicios {
  height: 330px;
  width: 320px;
  margin: 50px 5px;
  text-align: center;
}

.image-box-servicio {
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin: 0px 0px 25px 0px;
}

.title-box-servicio {
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: white;
  height: 55px;
}

.description-box-servicio {
  padding: 0px 26px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: white;
}

/*SECTION PILARES */

.container-pilares {
  background-color: white;
  min-height: 400px;
  width: 98vw;
  overflow-x: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-box-pilares {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #023f80;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 25px 0px;
}

.container-pilares-items {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #023f80;
  /*background-color: rgb(184, 184, 184);*/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 300px;
  margin-bottom: 25px;
}

.pilar-item {
  height: 320px;
  width: 300px;
  background-color: white;
  padding: 15px 25px;
  margin: 15px 5px;
  border-left: 0.2px solid rgba(221, 221, 221, 0.116);
  border-top: 0.2px solid rgba(221, 221, 221, 0.116);

  border-bottom: 0.2px solid rgba(221, 221, 221, 0.247);
  border-right: 0.2px solid rgba(221, 221, 221, 0.692);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.pilar-item:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pilar-item img {
  width: 60%;
}

.pilar-item h4 {
  color: #023f80;
  font-weight: 700;
  font-size: 18px;
}

.pilar-item p {
  color: #464646;
}

/* SECTION PROYECTOS */

.container-proyectos {
  background-color: black;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  padding: 25px 0px 0px 0px;
  border: none;
}

.container-proyectos-title {
  text-align: center;
}

.container-proyectos-title h2 {
  margin: 10px 0px 8px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.container-proyectos-title p {
  color: #d8d8d8;
  font-family: "Roboto", sans-serif;
  margin: 0px 0px 35px 0px;
}

.carousel-proyectos {
  height: 700px;
  background-color: #ffffff;
  position: relative;
}

.container-images-carousel {
  position: absolute;
  display: flex;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
.container-images-carousel img {
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.container-info-carousel {
  position: absolute;
  top: 35%;
  width: 100%;
}

.info-carousel {
  max-width: 500px;
  height: 100%;
  margin: 0px;
  text-align: center;
}

.info-carousel h4 {
  font-size: 50px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.info-carousel p {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 15px;
  color: white;
  padding: 0px 15px;
}

.carousel-bubble {
  position: absolute;
  bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-bubble {
  background-color: rgba(255, 255, 255, 0.548);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 25px;
  cursor: pointer;
}
.active {
  background-color: white;
}

@media (max-width: 900px) {
  .banner h1 {
    font-size: 40px;
  }
  .text-experiencia {
    font-size: 40px;
  }

  .description-box-servicio {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .container-boxes {
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .box-servicios {
    height: 300px;
  }
  .description-box-servicio {
    font-size: 12px;
  }
  .title-box-servicio {
    margin: 0px;
  }
}
