@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;0,900;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Noticia+Text:ital,wght@0,400;0,700;1,400;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
  opacity: 1;
  background-image: url(/static/media/background-building2.50265e5e.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin: 80px;
}

.txt-icon-facebook-home {
  color: white;
  text-decoration: none;
}
.icon-facebook-home {
  width: 60px;
  height: 60px;
  border: 2px solid white;
  border-radius: 50px;
  padding: 10px;
  margin-left: 15px;
}
/*
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}*/

.App-header {
  /*background-color: #282c34;*/
  /*background: rgb(2, 63, 128);
  background: linear-gradient(
    180deg,
    rgba(2, 63, 128, 1) 0%,
    rgba(0, 87, 180, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );*/

  /*background: rgb(2, 63, 128);
  background: linear-gradient(
    0deg,
    rgba(2, 63, 128, 1) 0%,
    rgba(0, 87, 180, 1) 50%,
    rgba(255, 255, 255, 1) 100%
  );*/

  /*background: rgb(2, 63, 128);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.9) 0%,
    rgba(0, 87, 180, 0.21) 56%,
    rgba(255, 255, 255, 0.363) 100%
  );*/

  background: rgb(2, 63, 128);
  background: linear-gradient(
    0deg,
    rgb(2 63 128) 0%,
    rgb(255 255 255 / 33%) 56%,
    rgb(255 255 255 / 19%) 100%
  );

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  text-shadow: -1px 6px 6px rgba(150, 150, 150, 0.54);
}

.App-header p {
  margin: 50px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.container-footer {
  background-color: black;
  color: white;
  font-family: "Roboto", sans-serif;
}

.container-logo-footer img {
  height: 50px;
}

.container-info-left {
  max-width: 400px;
  margin: 25px 0px;
}

.container-info-left p {
  text-align: justify;
}
.text-Copyright {
  font-size: 11px;
  margin: 0px;
}

.container-logo-footer {
  display: flex;
  margin: 25px 0px;
}

.container-codEtica-redSocial {
  display: flex;
  flex-wrap: wrap;
}

.container-info-middle {
  font-family: "Roboto", sans-serif;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 25px 0px;
}

.container-codEtic a {
  cursor: pointer;
  text-decoration: none;
}
.container-codEtic a p {
  margin: 0px;
  color: #fff;
}
.container-codEtic a:hover {
  color: rgba(255, 255, 255, 0.616);
  text-decoration: none;
}
.container-info-right {
  font-family: "Roboto", sans-serif;

  display: flex;
  height: 100%;
  align-items: flex-end;
}

.container-redSocial a {
  color: white;
  margin: 0px 10px 0px 0px;
}

.Navbar {
  overflow: hidden;
  background-color: blue;
  height: 72px;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(
    90deg,
    rgba(2, 63, 128, 0) 0%,
    rgba(2, 63, 128, 0.2397) 36.32%,
    rgba(2, 63, 128, 0.51) 62.93%,
    rgba(2, 63, 128, 0.51) 75.3%,
    rgba(2, 63, 128, 0.51) 88.26%,
    rgba(2, 63, 128, 0.51) 100%
  );
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.Navbar a {
  text-decoration: none;
}

.container-logo {
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-logo img {
  height: 50%;
}

.container-logo h1 {
  margin: 0px;
  font-size: 22px;
  color: white;
  /*transition: cubic-bezier(0.23, 1, 0.32, 1);*/
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

.Navbar h1:hover {
  /*transform: rotate(-1deg);*/
  text-shadow: 2px 2px rgba(199, 199, 199, 0);

  color: #ffffffa6;
}

/*TOGGLERRR*/
.toggler-menu {
  height: 50px;
  width: 50px;
  padding: auto;
  align-self: center;
  display: none;
}
.icon-menu {
  height: 72px;
}
.sidebar-menu {
  position: fixed;
  top: 0px;
  width: 250px;
  height: 100vh;
  background-color: #000000;
  z-index: 3;
  padding: 15px;

  transition: display 0.8s;
}

/*MENU*/

.container-menu {
  height: 100%;
  display: flex;
  width: 500px;
}
.container-menu ul {
  text-decoration: none;
  list-style: none;
  display: flex;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}
.container-menu ul a {
  text-decoration: none;
  min-height: 20px;
  width: auto;
  text-align: center;
  color: white;
  margin: 0px 10px;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  font-weight: 700;
}
.container-menu ul a:hover {
  color: #ffffff88;
  text-shadow: 2px 2px rgba(199, 199, 199, 0);
}

.container-menu ul li {
  font-size: 15px;
}

.container-Redsocial {
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-Redsocial a {
  text-decoration: none;
  color: white;
}

/*MENU DE SERVICIOS*/

.items-servicios {
  display: none;
}
.items-servicios-list {
  position: fixed;
  z-index: 5px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    rgba(2, 63, 128, 0) 0%,
    rgba(2, 63, 128, 0.2397) 36.32%,
    rgba(2, 63, 128, 0.51) 62.93%,
    rgba(2, 63, 128, 0.51) 75.3%,
    rgba(2, 63, 128, 0.51) 88.26%,
    rgba(2, 63, 128, 0.51) 100%
  );
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.items-servicios ol li {
  font-size: 12px;
  min-width: 75px;
  height: 30px;
  padding: 5px;
  text-align: left;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.39);
}
.li-servicios:hover .items-servicios {
  display: block;
}

@media (max-width: 900px) {
  .container-menu {
    width: 0px;
    height: 50px;
    overflow: hidden;
    display: none;
  }
  .container-logo {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .toggler-menu {
    height: 50px;
    width: 50px;
    padding: auto;
    align-self: center;
    display: block;
    z-index: 2;
  }
  .icon-menu {
    height: 50px;
    width: 120px;
    display: flex;
    margin-bottom: 20px;
    margin: 0px 15px;
  }

  .icon-menu img {
    align-self: center;
    cursor: pointer;
  }

  .icon-menu:hover .Navbar {
    height: 90px;
  }
  .sidebar-menu-title {
    display: flex;
  }
  .sidebar-menu-title a {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .sidebar-menu-title p {
    color: white;
    font-size: 25px;
    margin: 0px;
    font-weight: 700;
  }

  .sidebar-menu-title img {
    cursor: pointer;
  }
  .sidebar-menu ul {
    list-style: none;
    text-decoration: none;
    padding: 0px;
    margin: 20px 0px;

    text-align: center;
  }
  .sidebar-menu ul li {
    min-height: 50px;
    display: flex;
    justify-content: center;
  }
  .sidebar-menu ul li:hover {
  }
  .sidebar-menu ul li a {
    color: #fff;
    text-decoration: none;
    margin: 10px 0px;
  }

  /* RESPONSIVE*/

  #items-servicios-sidebar:hover .items-servicios-sidebar {
    display: block;
  }

  .items-servicios-sidebar {
    background-color: black;
    display: none;
  }
  .items-servicios-list-sidebar {
    padding: 0px;
  }
  .items-servicios-list-sidebar a li {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.993);
    padding: 1px 5px;
    font-size: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.39);
  }
  .items-servicios-list-sidebar a li:hover {
    background-color: rgba(97, 97, 97, 0.582);
  }
}

.section-bienvenida {
  position: relative;
  text-align: center;
  height: 90vh;
}
.banner {
  position: absolute;
  top: 50%;
  width: 100%;
  color: white;
  font-family: "Montserrat", sans-serif;
}
.banner h1 {
  font-size: 50px;
}
.boton-descubre {
  position: absolute;
  bottom: 2%;
  width: 100%;
  color: white;
  font-family: "Montserrat", sans-serif;
}

/*SECTION EXPERIENCIA*/

.container-experiencia {
  height: 90vh;
  background-image: url(/static/media/background-building.e2e61a8a.jpg);
  background-size: cover;
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  -webkit-backdrop-filter: brightness(60%);
          backdrop-filter: brightness(60%);
  -webkit-backdrop-filter: contrast(40%);
          backdrop-filter: contrast(40%);
  -webkit-backdrop-filter: drop-shadow(4px 4px 10px blue);
          backdrop-filter: drop-shadow(4px 4px 10px blue);
  -webkit-backdrop-filter: grayscale(30%);
          backdrop-filter: grayscale(30%);
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.text-experiencia {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 50px;
  color: white;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.text-experiencia strong {
  font-size: 65px;
}
/*SECTION SERVICIOS*/

.container-servicios {
  min-height: 1000px;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.container-servicios-title {
  margin: 50px 0px 15px 0px;
}

.container-servicios-title h2 {
  font-family: "Montserrat", sans-serif;
  color: white;
  font-size: 30px;
  font-weight: 700;
  text-shadow: 0px 4px 4px rgba(255, 255, 255, 0.25);
}
.container-servicios-title p {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  margin: 0px 20px;
  color: #d8d8d8;
}
.container-boxes {
  max-width: 900px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.box-servicios {
  height: 330px;
  width: 320px;
  margin: 50px 5px;
  text-align: center;
}

.image-box-servicio {
  width: 100%;
  height: 180px;
  object-fit: cover;
  margin: 0px 0px 25px 0px;
}

.title-box-servicio {
  padding: 0px 10px;
  font-family: "Montserrat", sans-serif;
  font-size: 18px;
  font-weight: 700;
  color: white;
  height: 55px;
}

.description-box-servicio {
  padding: 0px 26px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: white;
}

/*SECTION PILARES */

.container-pilares {
  background-color: white;
  min-height: 400px;
  width: 98vw;
  overflow-x: hidden;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title-box-pilares {
  font-family: "Montserrat", sans-serif;
  font-size: 36px;
  font-weight: 700;
  color: #023f80;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 25px 0px;
}

.container-pilares-items {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  color: #023f80;
  /*background-color: rgb(184, 184, 184);*/
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  min-width: 300px;
  margin-bottom: 25px;
}

.pilar-item {
  height: 320px;
  width: 300px;
  background-color: white;
  padding: 15px 25px;
  margin: 15px 5px;
  border-left: 0.2px solid rgba(221, 221, 221, 0.116);
  border-top: 0.2px solid rgba(221, 221, 221, 0.116);

  border-bottom: 0.2px solid rgba(221, 221, 221, 0.247);
  border-right: 0.2px solid rgba(221, 221, 221, 0.692);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
}

.pilar-item:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.pilar-item img {
  width: 60%;
}

.pilar-item h4 {
  color: #023f80;
  font-weight: 700;
  font-size: 18px;
}

.pilar-item p {
  color: #464646;
}

/* SECTION PROYECTOS */

.container-proyectos {
  background-color: black;
  min-height: 700px;
  display: flex;
  flex-direction: column;
  padding: 25px 0px 0px 0px;
  border: none;
}

.container-proyectos-title {
  text-align: center;
}

.container-proyectos-title h2 {
  margin: 10px 0px 8px 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  color: white;
}

.container-proyectos-title p {
  color: #d8d8d8;
  font-family: "Roboto", sans-serif;
  margin: 0px 0px 35px 0px;
}

.carousel-proyectos {
  height: 700px;
  background-color: #ffffff;
  position: relative;
}

.container-images-carousel {
  position: absolute;
  display: flex;
  overflow-x: hidden;
  height: 100%;
  width: 100%;
}
.container-images-carousel img {
  height: 100%;
  width: 100vw;
  object-fit: cover;
}

.container-info-carousel {
  position: absolute;
  top: 35%;
  width: 100%;
}

.info-carousel {
  max-width: 500px;
  height: 100%;
  margin: 0px;
  text-align: center;
}

.info-carousel h4 {
  font-size: 50px;
  color: white;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.info-carousel p {
  font-family: "Roboto", sans-serif;
  text-align: center;
  font-size: 15px;
  color: white;
  padding: 0px 15px;
}

.carousel-bubble {
  position: absolute;
  bottom: 6%;
  width: 100%;
  display: flex;
  justify-content: center;
}
.item-bubble {
  background-color: rgba(255, 255, 255, 0.548);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: 25px;
  cursor: pointer;
}
.active {
  background-color: white;
}

@media (max-width: 900px) {
  .banner h1 {
    font-size: 40px;
  }
  .text-experiencia {
    font-size: 40px;
  }

  .description-box-servicio {
    font-size: 16px;
  }
}

@media (max-width: 450px) {
  .container-boxes {
    max-width: 700px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
  }
  .box-servicios {
    height: 300px;
  }
  .description-box-servicio {
    font-size: 12px;
  }
  .title-box-servicio {
    margin: 0px;
  }
}

.Proyectos {
  padding-top: 72px;
  padding-bottom: 72px;
  min-height: 1000px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
}

.title-proyectos {
  padding: 50px 0px;
}

.title-proyectos h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
}
.title-proyectos p {
  text-align: justify;
  font-family: "Roboto", sans-serif;
  padding: 15px;
}
.container-proyectos-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: transparent;
}

.item-proyecto {
  background-image: url(/static/media/background-building.e2e61a8a.jpg);
  width: 520px;
  height: 350px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.item-proyecto:hover h2 {
  background-color: rgba(0, 0, 0, 0.61);
}
.item-proyecto h2 {
  color: white;
  font-family: "Montserrat", sans-serif;

  font-weight: 700;
  font-size: 20px;
  padding: 0px 10px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.336);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1050px) {
  .item-proyecto {
    background-color: rgb(132, 233, 1);
    width: 400px;
    height: 250px;
    margin: 10px;
  }
}

@media (max-width: 769px) {
  .item-proyecto {
    background-color: rgb(209, 255, 148);
    width: 500px;
    height: 300px;
    margin: 10px;
  }
}

.Proyecto {
  padding: 72px 0px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
}

.Proyecto-info {
  padding: 15px 0px;
}

.Proyecto-info img {
  width: 100%;
  object-fit: contain;
}

.proyecto-info-container {
  padding-top: 25px;
}

.title-proyecto {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.description-proyecto {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  max-width: 450px;
  text-align: justify;
}

.Ubicaciones-proyecto {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.Proyecto-images img {
  object-fit: cover;
  width: 100%;
  margin-top: 25px;
}

.datos-importantes {
  text-align: center;
}
.datos-importantes h3 {
  font-family: "Noticia Text", sans-serif;
  font-weight: 700;
}

.datos-importantes p {
  font-family: "Roboto", sans-serif;
}

.Nosotros {
  color: black;
  padding: 72px 0px;
}

.container-nosotros {
  padding: 25px 0px;
}

.container-nosotros__info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.info-Nosotros {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info-Nosotros h2 {
  font-family: "Montserrat", sans-serif;
}

.info-Nosotros p {
  font-family: "Roboto", sans-serif;
}

.box-skill {
  background-color: #023f80;
  width: 350px;
  height: 400px;
  color: white;
  margin: 25px 10px 25px 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.box-skill h2 {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
}
.box-skill h1 {
  font-size: 80px;
  align-self: flex-end;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  justify-self: flex-end;
}
.box-skill p {
  font-family: "Roboto", sans-serif;
  font-size: 35px;
}

.image-nosotros {
  max-width: 300px;
}

.image-equipo {
  width: 80%;
}

.image-certificacion {
  width: 150px;
}

.title-nosotros {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  margin: 50px 0px;
  text-align: center;
}
@media (max-width: 768px) {
  .image-certificacion {
    width: 100px;
    margin: 20px;
  }

  .box-skill {
    background-color: #023f80;
    width: 250px;
    height: 300px;
    color: white;
    margin: 25px 10px 50px 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .box-skill h2 {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
  }
  .box-skill h1 {
    font-size: 80px;
    align-self: flex-end;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    justify-self: flex-end;
  }
  .box-skill p {
    font-family: "Roboto", sans-serif;
    font-size: 27px;
  }
}

.Contacto {
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: url(/static/media/background-building.e2e61a8a.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

.container-contacto {
  margin-top: 72px;
}

.form-contacto {
  height: 400px;
  width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #023f80;
}

.form-contacto-inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.form-contacto-inputs input {
  /*margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;*/
  width: 48%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(255, 255, 255);
  outline: transparent;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 15px;
}

.form-contacto textarea {
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: transparent;
  outline: transparent;
  font-family: "Roboto", sans-serif;
  width: 99%;
  color: white;
  height: 150px;
  margin: 15px;
  font-size: 15px;
}

.form-contacto h2 {
  font-family: "Monserrat", sans-serif;
  color: white;
  margin: 25px 1px;
  align-self: flex-start;
}

.maps {
  border-radius: 50px;
}

.form-contacto button {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 570px) {
  .form-contacto {
    margin-top: 20px;
    border-radius: 0px;
  }
  .maps {
    margin-top: 20px;
    border-radius: 0px;
  }
}

.Pilares {
  min-height: 80vh;
  padding-top: 72px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%,
      #023f80 100%
    ),
    #ffffff;
}

.container-Pilares {
  background-color: transparent;
  min-height: 300px;
}

.container-Pilares h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.pilares-boxes {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.pilar-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.active {
  background-color: rgb(255, 255, 255);
}

.pilar-box img {
  width: 200px;
}
.content-pilares {
  background-color: rgb(255, 255, 255);
  height: 500px;
  padding: 45px 0px;
  display: flex;
  align-items: center;
}
.pilares-image-content {
  height: 300px;
  width: 400px;
}

@media (max-width: 768px) {
  .pilar-box {
    width: 33%;
  }
  .pilar-box h4 {
    font-size: 15px;
  }
  .pilar-box p {
    font-size: 10px;
  }
  .pilar-box img {
    width: 100px;
  }
  .pilares-image-content {
    height: 200px;
    width: 290px;
  }
}

.NotFound {
  height: 100vh;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Monserrat", sans-serif;
}

.Servicios {
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
  padding: 72px 0px;
}

.Container-Servicios {
  min-height: 100vh;
  padding: 25px 0px;
}
.Container-Servicios-info {
  padding: 5px 30px;
}
.Container-Servicios-info h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 700;
}
.Container-Servicios p {
  font-family: "Roboto", sans-serif;
  text-align: justify;
  font-size: 16px;
}
.container-proyectos-destacados {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.proyectos-destacados {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proyectos-destacados h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

.container-items-proyectos-destacados {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item-proyecto-destacado {
  width: 300px;
  margin: 50px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.item-proyecto-destacado:hover {
  border-color: 1px solid #ffffff2f;
  color: rgba(0, 0, 0, 0.678);
}

.item-proyecto-destacado h2 {
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.item-proyecto-destacado p {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .item-proyecto-destacado {
    padding: 15px;
    margin: 10px;
  }
  .Container-Servicios-info h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
  }
  .Container-Servicios p {
    font-family: "Roboto", sans-serif;
    text-align: justify;
    font-size: 12px;
  }
  .proyectos-destacados h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
  .item-proyecto-destacado h2 {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }

  .item-proyecto-destacado p {
    font-family: "Roboto", sans-serif;
    font-size: 10px;
  }
}

