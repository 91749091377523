.Servicios {
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
  padding: 72px 0px;
}

.Container-Servicios {
  min-height: 100vh;
  padding: 25px 0px;
}
.Container-Servicios-info {
  padding: 5px 30px;
}
.Container-Servicios-info h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 35px;
  font-weight: 700;
}
.Container-Servicios p {
  font-family: "Roboto", sans-serif;
  text-align: justify;
  font-size: 16px;
}
.container-proyectos-destacados {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.proyectos-destacados {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.proyectos-destacados h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-align: center;
}

.container-items-proyectos-destacados {
  max-width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.item-proyecto-destacado {
  width: 300px;
  margin: 50px;
  cursor: pointer;
  text-decoration: none;
  color: black;
}

.item-proyecto-destacado:hover {
  border-color: 1px solid #ffffff2f;
  color: rgba(0, 0, 0, 0.678);
}

.item-proyecto-destacado h2 {
  text-align: center;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;
}

.item-proyecto-destacado p {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 768px) {
  .item-proyecto-destacado {
    padding: 15px;
    margin: 10px;
  }
  .Container-Servicios-info h1 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
  }
  .Container-Servicios p {
    font-family: "Roboto", sans-serif;
    text-align: justify;
    font-size: 12px;
  }
  .proyectos-destacados h1 {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    font-size: 18px;
    text-align: center;
  }
  .item-proyecto-destacado h2 {
    text-align: center;
    font-size: 14px;
    font-family: "Montserrat", sans-serif;
  }

  .item-proyecto-destacado p {
    font-family: "Roboto", sans-serif;
    font-size: 10px;
  }
}
