.Navbar {
  overflow: hidden;
  background-color: blue;
  height: 72px;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(
    90deg,
    rgba(2, 63, 128, 0) 0%,
    rgba(2, 63, 128, 0.2397) 36.32%,
    rgba(2, 63, 128, 0.51) 62.93%,
    rgba(2, 63, 128, 0.51) 75.3%,
    rgba(2, 63, 128, 0.51) 88.26%,
    rgba(2, 63, 128, 0.51) 100%
  );
  display: flex;
  justify-content: space-around;
  position: fixed;
  width: 100%;
  z-index: 1;
}

.Navbar a {
  text-decoration: none;
}

.container-logo {
  height: 100%;
  width: 250px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.container-logo img {
  height: 50%;
}

.container-logo h1 {
  margin: 0px;
  font-size: 22px;
  color: white;
  /*transition: cubic-bezier(0.23, 1, 0.32, 1);*/
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
}

.Navbar h1:hover {
  /*transform: rotate(-1deg);*/
  text-shadow: 2px 2px rgba(199, 199, 199, 0);

  color: #ffffffa6;
}

/*TOGGLERRR*/
.toggler-menu {
  height: 50px;
  width: 50px;
  padding: auto;
  align-self: center;
  display: none;
}
.icon-menu {
  height: 72px;
}
.sidebar-menu {
  position: fixed;
  top: 0px;
  width: 250px;
  height: 100vh;
  background-color: #000000;
  z-index: 3;
  padding: 15px;

  transition: display 0.8s;
}

/*MENU*/

.container-menu {
  height: 100%;
  display: flex;
  width: 500px;
}
.container-menu ul {
  text-decoration: none;
  list-style: none;
  display: flex;
  padding: 0px;
  justify-content: space-between;
  align-items: center;
  margin: 0px;
}
.container-menu ul a {
  text-decoration: none;
  min-height: 20px;
  width: auto;
  text-align: center;
  color: white;
  margin: 0px 10px;
  transition-timing-function: ease-in;
  transition-duration: 0.2s;
  font-weight: 700;
}
.container-menu ul a:hover {
  color: #ffffff88;
  text-shadow: 2px 2px rgba(199, 199, 199, 0);
}

.container-menu ul li {
  font-size: 15px;
}

.container-Redsocial {
  width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container-Redsocial a {
  text-decoration: none;
  color: white;
}

/*MENU DE SERVICIOS*/

.items-servicios {
  display: none;
}
.items-servicios-list {
  position: fixed;
  z-index: 5px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(
    90deg,
    rgba(2, 63, 128, 0) 0%,
    rgba(2, 63, 128, 0.2397) 36.32%,
    rgba(2, 63, 128, 0.51) 62.93%,
    rgba(2, 63, 128, 0.51) 75.3%,
    rgba(2, 63, 128, 0.51) 88.26%,
    rgba(2, 63, 128, 0.51) 100%
  );
  margin: 0px;
  padding: 0px;
  list-style: none;
}
.items-servicios ol li {
  font-size: 12px;
  min-width: 75px;
  height: 30px;
  padding: 5px;
  text-align: left;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.39);
}
.li-servicios:hover .items-servicios {
  display: block;
}

@media (max-width: 900px) {
  .container-menu {
    width: 0px;
    height: 50px;
    overflow: hidden;
    display: none;
  }
  .container-logo {
    height: 100%;
    width: 200px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  .toggler-menu {
    height: 50px;
    width: 50px;
    padding: auto;
    align-self: center;
    display: block;
    z-index: 2;
  }
  .icon-menu {
    height: 50px;
    width: 120px;
    display: flex;
    margin-bottom: 20px;
    margin: 0px 15px;
  }

  .icon-menu img {
    align-self: center;
    cursor: pointer;
  }

  .icon-menu:hover .Navbar {
    height: 90px;
  }
  .sidebar-menu-title {
    display: flex;
  }
  .sidebar-menu-title a {
    text-decoration: none;
    display: flex;
    align-items: center;
    margin-left: 15px;
  }
  .sidebar-menu-title p {
    color: white;
    font-size: 25px;
    margin: 0px;
    font-weight: 700;
  }

  .sidebar-menu-title img {
    cursor: pointer;
  }
  .sidebar-menu ul {
    list-style: none;
    text-decoration: none;
    padding: 0px;
    margin: 20px 0px;

    text-align: center;
  }
  .sidebar-menu ul li {
    min-height: 50px;
    display: flex;
    justify-content: center;
  }
  .sidebar-menu ul li:hover {
  }
  .sidebar-menu ul li a {
    color: #fff;
    text-decoration: none;
    margin: 10px 0px;
  }

  /* RESPONSIVE*/

  #items-servicios-sidebar:hover .items-servicios-sidebar {
    display: block;
  }

  .items-servicios-sidebar {
    background-color: black;
    display: none;
  }
  .items-servicios-list-sidebar {
    padding: 0px;
  }
  .items-servicios-list-sidebar a li {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.993);
    padding: 1px 5px;
    font-size: 10px;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.39);
  }
  .items-servicios-list-sidebar a li:hover {
    background-color: rgba(97, 97, 97, 0.582);
  }
}
