.Proyectos {
  padding-top: 72px;
  padding-bottom: 72px;
  min-height: 1000px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
}

.title-proyectos {
  padding: 50px 0px;
}

.title-proyectos h1 {
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 40px;
}
.title-proyectos p {
  text-align: justify;
  font-family: "Roboto", sans-serif;
  padding: 15px;
}
.container-proyectos-items {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: transparent;
}

.item-proyecto {
  background-image: url("../assets/images/background-building.jpg");
  width: 520px;
  height: 350px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.item-proyecto:hover h2 {
  background-color: rgba(0, 0, 0, 0.61);
}
.item-proyecto h2 {
  color: white;
  font-family: "Montserrat", sans-serif;

  font-weight: 700;
  font-size: 20px;
  padding: 0px 10px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background-color: rgba(0, 0, 0, 0.336);
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@media (max-width: 1050px) {
  .item-proyecto {
    background-color: rgb(132, 233, 1);
    width: 400px;
    height: 250px;
    margin: 10px;
  }
}

@media (max-width: 769px) {
  .item-proyecto {
    background-color: rgb(209, 255, 148);
    width: 500px;
    height: 300px;
    margin: 10px;
  }
}
