.Proyecto {
  padding: 72px 0px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%
    ),
    #f8f8f8;
}

.Proyecto-info {
  padding: 15px 0px;
}

.Proyecto-info img {
  width: 100%;
  object-fit: contain;
}

.proyecto-info-container {
  padding-top: 25px;
}

.title-proyecto {
  font-size: 20px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.description-proyecto {
  font-family: "Roboto", sans-serif;
  margin: 0px;
  padding: 0px;
  font-size: 10px;
  max-width: 450px;
  text-align: justify;
}

.Ubicaciones-proyecto {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 500;
}
.Proyecto-images img {
  object-fit: cover;
  width: 100%;
  margin-top: 25px;
}

.datos-importantes {
  text-align: center;
}
.datos-importantes h3 {
  font-family: "Noticia Text", sans-serif;
  font-weight: 700;
}

.datos-importantes p {
  font-family: "Roboto", sans-serif;
}
