.container-footer {
  background-color: black;
  color: white;
  font-family: "Roboto", sans-serif;
}

.container-logo-footer img {
  height: 50px;
}

.container-info-left {
  max-width: 400px;
  margin: 25px 0px;
}

.container-info-left p {
  text-align: justify;
}
.text-Copyright {
  font-size: 11px;
  margin: 0px;
}

.container-logo-footer {
  display: flex;
  margin: 25px 0px;
}

.container-codEtica-redSocial {
  display: flex;
  flex-wrap: wrap;
}

.container-info-middle {
  font-family: "Roboto", sans-serif;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 0px 25px 0px;
}

.container-codEtic a {
  cursor: pointer;
  text-decoration: none;
}
.container-codEtic a p {
  margin: 0px;
  color: #fff;
}
.container-codEtic a:hover {
  color: rgba(255, 255, 255, 0.616);
  text-decoration: none;
}
.container-info-right {
  font-family: "Roboto", sans-serif;

  display: flex;
  height: 100%;
  align-items: flex-end;
}

.container-redSocial a {
  color: white;
  margin: 0px 10px 0px 0px;
}
