.Pilares {
  min-height: 80vh;
  padding-top: 72px;
  background: linear-gradient(
      180deg,
      #ffffff 0%,
      rgba(68, 113, 161, 0.5) 37.5%,
      #023f80 100%,
      #023f80 100%
    ),
    #ffffff;
}

.container-Pilares {
  background-color: transparent;
  min-height: 300px;
}

.container-Pilares h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

.pilares-boxes {
  min-width: 100%;
  height: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.pilar-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  cursor: pointer;
}

.active {
  background-color: rgb(255, 255, 255);
}

.pilar-box img {
  width: 200px;
}
.content-pilares {
  background-color: rgb(255, 255, 255);
  height: 500px;
  padding: 45px 0px;
  display: flex;
  align-items: center;
}
.pilares-image-content {
  height: 300px;
  width: 400px;
}

@media (max-width: 768px) {
  .pilar-box {
    width: 33%;
  }
  .pilar-box h4 {
    font-size: 15px;
  }
  .pilar-box p {
    font-size: 10px;
  }
  .pilar-box img {
    width: 100px;
  }
  .pilares-image-content {
    height: 200px;
    width: 290px;
  }
}
