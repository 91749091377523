.Nosotros {
  color: black;
  padding: 72px 0px;
}

.container-nosotros {
  padding: 25px 0px;
}

.container-nosotros__info {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.info-Nosotros {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.info-Nosotros h2 {
  font-family: "Montserrat", sans-serif;
}

.info-Nosotros p {
  font-family: "Roboto", sans-serif;
}

.box-skill {
  background-color: #023f80;
  width: 350px;
  height: 400px;
  color: white;
  margin: 25px 10px 25px 10px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.box-skill h2 {
  font-size: 40px;
  font-family: "Roboto", sans-serif;
}
.box-skill h1 {
  font-size: 80px;
  align-self: flex-end;
  font-family: "Roboto", sans-serif;
  font-weight: 900;
  justify-self: flex-end;
}
.box-skill p {
  font-family: "Roboto", sans-serif;
  font-size: 35px;
}

.image-nosotros {
  max-width: 300px;
}

.image-equipo {
  width: 80%;
}

.image-certificacion {
  width: 150px;
}

.title-nosotros {
  font-family: "Montserrat", sans-serif;
  font-size: 32px;
  margin: 50px 0px;
  text-align: center;
}
@media (max-width: 768px) {
  .image-certificacion {
    width: 100px;
    margin: 20px;
  }

  .box-skill {
    background-color: #023f80;
    width: 250px;
    height: 300px;
    color: white;
    margin: 25px 10px 50px 10px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .box-skill h2 {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
  }
  .box-skill h1 {
    font-size: 80px;
    align-self: flex-end;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    justify-self: flex-end;
  }
  .box-skill p {
    font-family: "Roboto", sans-serif;
    font-size: 27px;
  }
}
