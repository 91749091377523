.Contacto {
  min-height: 80vh;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/images/background-building.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.container-contacto {
  margin-top: 72px;
}

.form-contacto {
  height: 400px;
  width: 600px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: #023f80;
}

.form-contacto-inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.form-contacto-inputs input {
  /*margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 15px;*/
  width: 48%;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid rgb(255, 255, 255);
  outline: transparent;
  font-family: "Roboto", sans-serif;
  color: white;
  font-size: 15px;
}

.form-contacto textarea {
  border: none;
  border-bottom: 1px solid rgb(255, 255, 255);
  background-color: transparent;
  outline: transparent;
  font-family: "Roboto", sans-serif;
  width: 99%;
  color: white;
  height: 150px;
  margin: 15px;
  font-size: 15px;
}

.form-contacto h2 {
  font-family: "Monserrat", sans-serif;
  color: white;
  margin: 25px 1px;
  align-self: flex-start;
}

.maps {
  border-radius: 50px;
}

.form-contacto button {
  font-family: "Roboto", sans-serif;
}

@media (max-width: 570px) {
  .form-contacto {
    margin-top: 20px;
    border-radius: 0px;
  }
  .maps {
    margin-top: 20px;
    border-radius: 0px;
  }
}
